import Home from './home';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import {getComponentStaticProps, HeaderProps} from '../components/header/headerAppyShop';
import {appyShopConfig} from '../config'

export default function Index(props: { headerProps: HeaderProps }) {
  return (
    <Home headerProps={props.headerProps} />

  )
}

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...await serverSideTranslations(locale, ['common']),
    headerProps: await getComponentStaticProps(locale)
  },
  revalidate: appyShopConfig.revalidateData
})
